import React from "react"
import { Router, Redirect } from "@reach/router"

const IndexPage = () => (
  <React.Fragment>
    <Router>
      <Redirect noThrow from="/" to="/blog" />
    </Router>
  </React.Fragment>
)

export default IndexPage
